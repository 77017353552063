import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AdminAuthProvider } from "./context/adminAuth";
import { BrandContactAuthProvider } from "./context/brandContactAuth";
import { CustomerAuthProvider } from "./context/customerAuth";
import { GuestAuthProvider } from "./context/guestAuth";
import AdminDashboard from "./pages/creator/AdminDashboard";
import CompleteRegistration from "./pages/creator/CompleteRegistration";
import DownloadAppRedirect from "./pages/creator/DownloadAppRedirect";
import EditPet from "./pages/creator/EditPet";
import LoginAdmin from "./pages/creator/LoginAdmin";
import LoginCustomer from "./pages/creator/LoginCustomer";
import LoginSeller from "./pages/creator/LoginSeller";
import LogoutCustomer from "./pages/creator/LogoutCustomer";
import NotFound from "./pages/creator/NotFound";
import Notifications from "./pages/creator/Notifications";
import PetAccountSettings from "./pages/creator/PetAccountSettings";
import Profile from "./pages/creator/Profile";
import Puzzles from "./pages/creator/Puzzles";
import RegisterCustomer from "./pages/creator/RegisterCustomer";
import RegisterSeller from "./pages/creator/RegisterSeller";
import ResetCustomerPassword from "./pages/creator/ResetPetPassword";
import ResetSellerPassword from "./pages/creator/ResetSellerPassword";
import SellerAccountSettings from "./pages/creator/SellerAccountSettings";
import SellerAds from "./pages/creator/SellerAds";
import SellerDashboard from "./pages/creator/SellerDashboard";
import Shop from "./pages/creator/Shop";

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_51Q7I66LpWZs7QpMbVQV87sqj1Pj48HjEgbSz3VAlU56mHXOgYWBoWYbvB6AcWop5re9IvI6bDvjeXuqgnmPVRUql00xlPYMxlz"
    : "pk_test_51Q7I66LpWZs7QpMbtfgEpB85EoYb8F9bkn6rqM44X70PUiV9xQcAr2XUJOeuPs5uDXI4BVPgu6gm3YIt3d6kW41B00t1Rww0RO"
);

function App() {
  // const handleGotKeyboardSize = (e) => {
  //   if (isRunningInRNWebView) {
  //     if (e && e.data && e?.data?.keyboardHeight) {
  //       localStorage.setItem("keyboardHeight", e?.data?.keyboardHeight || 0);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("message", handleGotKeyboardSize);

  //   return () => {
  //     document.removeEventListener("message", handleGotKeyboardSize);
  //   };
  // }, []);

  // const handleGotTopbarHeight = (e) => {
  //   if (isRunningInRNWebView) {
  //     // console.log("kooko");
  //     if (e && e.data && e?.data?.topbarHeight) {
  //       localStorage.setItem("topbarHeight", e?.data?.topbarHeight || 0);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("message", handleGotTopbarHeight);

  //   return () => {
  //     document.removeEventListener("message", handleGotTopbarHeight);
  //   };
  // }, []);

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/download-app" element={<DownloadAppRedirect />} />

          {/* <Route
            exact
            path="/search"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Search />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          /> */}
          {/* 
          <Route
            exact
            path="/search/:categoryShortForm"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Search />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          /> */}

          <Route
            exact
            path="/shop"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Shop />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/shop/:categoryShortForm"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Shop />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          {/* 
          <Route
            exact
            path="/activity/:shortForm"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Activity />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          /> */}

          <Route
            exact
            path="/pet/:username"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Profile />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          {/* <Route
            exact
            path="/post/:shortForm"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <CreatePost />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          /> */}
          {/* <Route
            exact
            path="/post"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <CreatePost />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          /> */}

          <Route
            exact
            path="/login"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <LoginCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/login/redirect/:prevPath"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <LoginCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/register"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/register/:joinedFor"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/register/:joinedFor/redirect/:prevPath"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/register/redirect/:prevPath"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/signup/:joinedFor"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/editPet"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <EditPet />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/completeRegistration/:step"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <CompleteRegistration />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/completeRegistration/:step/redirect/:prevPath"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <CompleteRegistration />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/logout"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <LogoutCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/resetPassword"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <ResetCustomerPassword />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/resetPassword/:resetCode"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <ResetCustomerPassword />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/resetSellerPassword"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <ResetSellerPassword />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/resetSellerPassword/:resetCode"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <ResetSellerPassword />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/loginSeller"
            element={
              <BrandContactAuthProvider>
                <LoginSeller />
              </BrandContactAuthProvider>
            }
          />
          <Route
            exact
            path="/registerSeller"
            element={
              <BrandContactAuthProvider>
                <RegisterSeller />
              </BrandContactAuthProvider>
            }
          />

          <Route
            exact
            path="/business/dashboard"
            element={
              <BrandContactAuthProvider>
                <SellerDashboard />
              </BrandContactAuthProvider>
            }
          />
          <Route
            exact
            path="/business/ads"
            element={
              <BrandContactAuthProvider>
                <SellerAds />
              </BrandContactAuthProvider>
            }
          />
          <Route
            exact
            path="/business/dashboard"
            element={
              <BrandContactAuthProvider>
                <SellerDashboard />
              </BrandContactAuthProvider>
            }
          />
          <Route
            exact
            path="/business/settings"
            element={
              <BrandContactAuthProvider>
                <SellerAccountSettings />
              </BrandContactAuthProvider>
            }
          />

          <Route
            exact
            path="/settings"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <PetAccountSettings />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/settings/:shouldUnsubscribe"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <PetAccountSettings />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/notifications"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Notifications />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          {/*
          
         
          <Route
            exact
            path="/org/:nameForUrl"
            element={
                <GuestAuthProvider>
                <CustomerAuthProvider>
                <Organization />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/battle/:quizId"
            element={
              
                  <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Battle />
                  </CustomerAuthProvider>
              </GuestAuthProvider>
              
            }
          />
         
           */}

          <Route
            exact
            path="/play/:puzzleId"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Puzzles />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/play"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Puzzles />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          {/*
          <Route
            exact
            path="/buySubscription"
            element={
              
                  <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Puzzles />
                  </CustomerAuthProvider>
              </GuestAuthProvider>
              
            }
          />
          <Route
            exact
            path="/buySubscription/:subscriptionType"
            element={
              
                  <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Puzzles />
                  </CustomerAuthProvider>
              </GuestAuthProvider>
              
            }
          />

          <Route
            exact
            path="/buyTokens"
            element={
              
                  <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Puzzles />
                  </CustomerAuthProvider>
              </GuestAuthProvider>
              
            }
          />
          <Route
            exact
            path="/buyTokens/:numOfCoins"
            element={
              
                  <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Puzzles />
                  </CustomerAuthProvider>
              </GuestAuthProvider>
              
            }
          /> */}
          {/* <Route
            exact
            path="/"
            element={
              
                  <GuestAuthProvider>
                <CustomerAuthProvider>
                  <AmazonRedirect />
                  </CustomerAuthProvider>
              </GuestAuthProvider>
              
            }
          /> */}
          <Route
            exact
            path="/adminLogin"
            element={
              <AdminAuthProvider>
                <LoginAdmin />
              </AdminAuthProvider>
            }
          />
          <Route
            exact
            path="/adminDashboard/:typeOfData"
            element={
              <AdminAuthProvider>
                <AdminDashboard />
              </AdminAuthProvider>
            }
          />
          <Route
            exact
            path="/adminDashboard"
            element={
              <AdminAuthProvider>
                <AdminDashboard />
              </AdminAuthProvider>
            }
          />

          <Route
            exact
            path="/"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Puzzles />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          {/* <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} /> */}
        </Routes>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
