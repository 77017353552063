import { useQuery } from "@apollo/client";
import React from "react";
import { useLocation } from "react-router-dom";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_PET } from "../../util/gqlFunctions";
import { defaultPicUrl } from "../../util/hooks";

export default function MobileFooter({ navigate, page, finishedLoading }) {
  const location = useLocation();
  // const [lastPage, setLastPage] = useState(
  //   location.state?.from?.pathname
  //     ? location.state?.from?.pathname.split("/")[1]
  //     : null
  // );

  const { data: { getPetByCustomer: targetPet } = {} } = useQuery(FETCH_PET, {
    client: customerClient,
    // fetchPolicy: "network-only",
  });

  // useEffect(() => {
  //   if (location.state?.from?.pathname) {
  //     let tempLastPage = location.state?.from?.pathname;
  //     if (tempLastPage) {
  //       tempLastPage = tempLastPage.split("/")[1];
  //       if (tempLastPage) {
  //         setLastPage(tempLastPage);
  //       }
  //     }
  //   }
  // }, [location]);

  return (
    <div
      className={`w-full text-sm fixed xshort:hidden lg:hidden h-[5.5rem] short:h-[4rem] px-6 bottom-0 bg-gradient-to-t  inset-x-0 z-10 grid grid-cols-3 text-black from-light-blue to-transparent via-light-blue/60`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          navigate("/play", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className={`${
            page === "focus" ? "bg-lighter-blue" : ""
          } rounded-full short:h-6 h-8 stroke-current`}
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="30" cy="30" r="28.5" stroke="black" strokeWidth="3" />
          <path
            d="M26.5574 28.6516L22.2366 32.9724M22.2791 28.6941L26.5999 33.0149M32.1674 30.8333H32.1816M37.7491 30.8333H37.7633M34.9583 33.6383V33.6099M34.9583 28.0566V28.0283M31.4308 13.8333L31.4166 15.2641C31.4024 16.0433 30.7791 16.6666 29.9999 16.6666H29.9574C29.1783 16.6666 28.5549 17.3041 28.5549 18.0833C28.5549 18.8624 29.1924 19.4999 29.9716 19.4999H31.3883M25.7499 42.1666H34.2499C41.3333 42.1666 44.1666 39.3333 44.1666 32.2499V29.4166C44.1666 22.3333 41.3333 19.4999 34.2499 19.4999H25.7499C18.6666 19.4999 15.8333 22.3333 15.8333 29.4166V32.2499C15.8333 39.3333 18.6666 42.1666 25.7499 42.1666Z"
            stroke="black"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="leading-none text-center tracking-wide">Play</p>
      </button>
      {/* <button
        onClick={(e) => {
          e.preventDefault();
          navigate("/shop", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className={`${
            page === "shop" ? "bg-lighter-blue" : ""
          } rounded-full short:h-6 h-8 stroke-current`}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M10.1333 11.7333L12.1704 9.06665H20.3185L22.3556 11.7333M10.1333 11.7333V21.0666C10.1333 21.4203 10.2764 21.7594 10.5311 22.0095C10.7858 22.2595 11.1312 22.4 11.4914 22.4H20.9975C21.3577 22.4 21.7031 22.2595 21.9578 22.0095C22.2125 21.7594 22.3556 21.4203 22.3556 21.0666V11.7333M10.1333 11.7333H22.3556M18.9605 14.4C18.9605 15.1072 18.6743 15.7855 18.165 16.2856C17.6556 16.7857 16.9648 17.0666 16.2444 17.0666C15.5241 17.0666 14.8333 16.7857 14.3239 16.2856C13.8145 15.7855 13.5284 15.1072 13.5284 14.4"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="leading-none text-center tracking-wide">Shop</p>
      </button> */}
      {/* <button
        onClick={(e) => {
          e.preventDefault();
          navigate("/post", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className={`bg-yellow-400 rounded-full short:h-6 h-8 `}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M22.1333 16.8381H17.1048V21.8666H15.4286V16.8381H10.4V15.1619H15.4286V10.1333H17.1048V15.1619H22.1333V16.8381Z"
            fill="black"
          />
        </svg>

        <p className="leading-none text-center tracking-wide">Memo</p>
      </button> */}
      <button
        onClick={(e) => {
          e.preventDefault();
          if (targetPet) {
            navigate("/pet/" + targetPet.username, {
              state: { from: location },
            });
          } else {
            navigate("/", { state: { from: location } });
          }
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <img
          className="short:h-6 h-8 rounded-full border-2 border-black"
          src={targetPet?.profilePicUrl || defaultPicUrl}
          alt={targetPet?.name || "Profile"}
        />

        <p className="leading-none text-center tracking-wide">Home</p>
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();

          navigate("/shop", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className={`${
            page === "shop" ? "bg-lighter-blue" : ""
          } rounded-full short:h-6 h-8 stroke-current`}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M10.1333 11.7333L12.1704 9.06665H20.3185L22.3556 11.7333M10.1333 11.7333V21.0666C10.1333 21.4203 10.2764 21.7594 10.5311 22.0095C10.7858 22.2595 11.1312 22.4 11.4914 22.4H20.9975C21.3577 22.4 21.7031 22.2595 21.9578 22.0095C22.2125 21.7594 22.3556 21.4203 22.3556 21.0666V11.7333M10.1333 11.7333H22.3556M18.9605 14.4C18.9605 15.1072 18.6743 15.7855 18.165 16.2856C17.6556 16.7857 16.9648 17.0666 16.2444 17.0666C15.5241 17.0666 14.8333 16.7857 14.3239 16.2856C13.8145 15.7855 13.5284 15.1072 13.5284 14.4"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="leading-none text-center tracking-wide">Shop</p>
      </button>
    </div>
  );
}
