import { useQuery } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import { FETCH_RECOMMENDED_FORUM_POSTS } from "../../util/gqlFunctions";
import { defaultPicUrl } from "../../util/hooks";
import RateForumPostBox from "./RateForumPostBox";

export default function ReplyDrawer({
  comment,
  navigate,
  setValues,
  values,
  targetCustomer,
  newReplies,
  isFloating,
}) {
  const [openedOnce, setOpenedOnce] = useState(false);

  const [showReplies, setShowReplies] = useState(false);
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(false);

  const { fetchMore: fetchMoreReplies } = useQuery(
    FETCH_RECOMMENDED_FORUM_POSTS,
    {
      client: targetCustomer ? customerClient : guestClient,

      variables: {
        puzzleId: comment.puzzleId,
        headForumPostId: comment?.id || "",
        searchTerm: null,
        type: 3,
        pageSize: 3,
        seenIds: [],
      },
      skip: true,
      // fetchPolicy: 'cache-and-network',
    }
  );

  const fetchReplies = () => {
    let newSeenIds = replies?.map((r) => r.id) || [];

    if (newReplies && newReplies.length > 0) {
      let newReplyIds = newReplies.map((r) => r.id);
      if (newSeenIds && newSeenIds.length > 0) {
        newSeenIds = [...newSeenIds, ...newReplyIds];
      } else {
        newSeenIds = [...newReplyIds];
      }
    }

    fetchMoreReplies({
      variables: {
        puzzleId: comment.puzzleId,
        headForumPostId: comment?.id || "",
        searchTerm: null,
        type: 3,
        pageSize: openedOnce ? 6 : 3,
        seenIds: newSeenIds,
      },

      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          setLoading(false);
          return prev;
        }
        const newFetchedReplies =
          fetchMoreResult.getRecommendedForumPostsBySearch;
        if (!openedOnce) {
          setOpenedOnce(true);
        }
        setShowReplies(true);

        setReplies([...replies, ...newFetchedReplies]);
        setLoading(false);
      },
    });
  };

  return (
    <>
      {showReplies ? <div className="h-2"></div> : <></>}
      {showReplies && replies && replies.length > 0 ? (
        replies.map((reply, index) => (
          <div
            key={reply.id}
            className="flex mb-2 sm:mb-4 text-left shrink-0 w-full items-start justify-start"
          >
            <div
              className={`${
                reply.id === values.forumPostRepliedToId
                  ? "bg-light-blue"
                  : "bg-gray-100/50"
              } flex-shrink-0 mr-2 2xl:mr-4 transition-all overflow-hidden short:p-[.2rem] p-0.5 2xl:p-1.5 rounded-full aspect-square w-8 2xl:w-12 short:w-6 flex`}
            >
              <img
                src={reply.profilePicUrl || defaultPicUrl}
                alt={reply.displayName}
                className="border-black bg-black short:border-[1.5px] border-2 2xl:border-4 box-border rounded-full object-cover aspect-square"
              />
            </div>
            <div className="flex-1 space-y-0.5 w-full ">
              <p className="font-semibold 2xl:text-lg short:text-xs text-sm leading-tight w-full">
                {reply.displayName}
                {reply.forumPostRepliedToId !== comment.id ? (
                  <>
                    <span className="font-semibold 2xl:text-base text-xs short:text-xs leading-tight ">
                      {" "}
                      ▶{" "}
                    </span>
                    {reply.repliedToDisplayName}
                  </>
                ) : (
                  ""
                )}
              </p>
              <p className="line-clamp-5 text-lg 2xl:text-2xl short:text-sm leading-tight w-full">
                {reply.details}
              </p>

              <div className="flex 2xl:text-lg short:text-xs text-sm items-center justify-start space-x-2 ">
                <p className="leading-tight">
                  {moment(reply.createdAt).fromNow()}
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (values.forumPostRepliedToId === reply.id) {
                      setValues({
                        ...values,
                        repliedToDisplayName: null,
                        forumPostRepliedToId: null,
                        type: 0,
                      });
                    } else {
                      setValues({
                        ...values,
                        repliedToDisplayName: reply.displayName,
                        forumPostRepliedToId: reply.id,
                        type: 3,
                      });
                    }
                  }}
                  className="2xl:text-xl hover:text-gray-600 transition-all short:text-sm leading-tight font-semibold"
                >
                  Reply
                </button>
                <div style={{ flex: 1 }}></div>

                <RateForumPostBox
                  forumPostId={reply.id}
                  targetCustomer={targetCustomer}
                  isFloating={isFloating}
                  isMini={true}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}

      {showReplies && newReplies && newReplies.length > 0 ? (
        newReplies.map((reply, index) => (
          <div
            key={reply.id}
            className="flex mb-2 sm:mb-4 text-left shrink-0 w-full items-start justify-start"
          >
            <div
              className={`${
                reply.id === values.forumPostRepliedToId
                  ? "bg-light-blue"
                  : "bg-gray-100/50"
              } flex-shrink-0 mr-2 2xl:mr-4 transition-all overflow-hidden short:p-[.2rem] p-0.5 2xl:p-1.5 rounded-full aspect-square w-8 2xl:w-12 short:w-6 flex`}
            >
              <img
                src={reply.profilePicUrl || defaultPicUrl}
                alt={reply.displayName}
                className="border-black bg-black short:border-[1.5px] border-2 2xl:border-4 box-border rounded-full object-cover aspect-square"
              />
            </div>
            <div className="flex-1 space-y-1 w-full">
              <p className="font-semibold 2xl:text-lg short:text-xs text-sm leading-tight w-full">
                {reply.displayName}
                {reply.forumPostRepliedToId !== comment.id ? (
                  <>
                    <span className="font-semibold 2xl:text-base text-xs short:text-xs leading-tight ">
                      {" "}
                      ▶{" "}
                    </span>
                    {reply.repliedToDisplayName}
                  </>
                ) : (
                  ""
                )}
              </p>
              <p className="line-clamp-5 text-lg 2xl:text-2xl short:text-sm leading-tight w-full">
                {reply.details}
              </p>

              <div className="flex 2xl:text-lg short:text-xs text-sm items-center justify-start space-x-2">
                <p className="leading-tight">
                  {moment(reply.createdAt).fromNow()}
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (values.forumPostRepliedToId === reply.id) {
                      setValues({
                        ...values,
                        repliedToDisplayName: null,
                        forumPostRepliedToId: null,
                        type: 0,
                      });
                    } else {
                      setValues({
                        ...values,
                        repliedToDisplayName: reply.displayName,
                        forumPostRepliedToId: reply.id,
                        type: 3,
                      });
                    }
                  }}
                  className="2xl:text-xl hover:text-gray-600 transition-all short:text-sm leading-tight font-semibold"
                >
                  Reply
                </button>
                <div style={{ flex: 1 }}></div>

                <RateForumPostBox
                  forumPostId={reply.id}
                  targetCustomer={targetCustomer}
                  isFloating={isFloating}
                  isMini={true}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}

      {loading ? (
        <svg
          className={` h-5 aspect-square stroke-current m-auto motion-safe:animate-spin`}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.636 3.636A9 9 0 0119 10"
            strokeWidth={2}
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <></>
      )}

      <div className="flex items-center justify-between space-x-2">
        {!showReplies ||
        (showReplies && comment.rNumOfComments > replies?.length) ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (
                !showReplies ||
                (showReplies && comment.rNumOfComments > replies?.length)
              ) {
                setLoading(true);

                fetchReplies();
              } else {
                setOpenedOnce(false);
                setShowReplies(false);
              }
            }}
            className="flex sm:hover:text-gray-500 flex-row py-2 space-x-1.5 items-center justify-start"
            disabled={loading}
          >
            <svg
              className=" transition-all w-5 shrink-0 stroke-current"
              viewBox="0 0 22 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="22" y2="0.5" />
            </svg>

            <p className="text-left transition-all text-sm leading-tight ">
              {!showReplies ||
              (showReplies && comment.rNumOfComments > replies?.length)
                ? openedOnce
                  ? `View ${comment.rNumOfComments - replies?.length} more`
                  : `View ${comment.rNumOfComments} ${
                      comment.rNumOfComments === 1 ? "reply" : "replies"
                    }`
                : `Hide ${comment.rNumOfComments} ${
                    comment.rNumOfComments === 1 ? "reply" : "replies"
                  }`}
            </p>
          </button>
        ) : (
          <></>
        )}

        {showReplies ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowReplies(false);
              setOpenedOnce(false);
            }}
            className={`${
              !showReplies ||
              (showReplies && comment.rNumOfComments > replies?.length)
                ? ""
                : "flex-1"
            } sm:hover:text-gray-500 transition-all flex items-center gap-1.5 px-1.5`}
          >
            <p className=" text-sm leading-tight font-semibold">Hide</p>
          </button>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
