import { useQuery } from "@apollo/client";
import React from "react";
import { useLocation } from "react-router-dom";
import { brandContactClient, customerClient } from "../../GraphqlApolloClients";
import { FETCH_NUM_UNCLICKED_NOTIFICATIONS } from "../../util/gqlFunctions";
import Logo from "./Logo";
import PlayerCoinCounter from "./PlayerCoinCounter";

export default function TopBar({
  activityShortForm,
  orgShortForm,
  navigate,
  toggleBuyCoinsForm,
  targetPet,
  setShouldShowMiniNavBar,
  setIsFirstTime,
  targetCustomer,
  targetGuestCustomer,
  seller,
  customer,
  toggleBuySubscriptionForm,
  page,
  activityName,
  activityLogoUrl,
  orgLogoUrl,
  toggleMultiTopicBattleForm,
  toggleOneTopicBattleForm,
  activityId,
  forumTitle,
  forumLogoUrl,
  orgNameForUrl,
  prevPath,
  postNumber,
  values,
  setValues,
  isSeller,
}) {
  const location = useLocation();
  const {
    data: { getNumUnclickedNotifications: numUnclickedNotifications } = 0,
  } = useQuery(FETCH_NUM_UNCLICKED_NOTIFICATIONS, {
    client: isSeller ? brandContactClient : customerClient,
    fetchPolicy: "cache-and-network",
  });

  return (
    <div
      className={`${
        page === "CreatePost" ||
        page === "Notifications" ||
        page === "Friends" ||
        (page === "Play" && (orgShortForm || activityShortForm)) ||
        (page === "Comms" && forumTitle)
          ? ""
          : "space-x-2 sm:space-x-4"
      } relative z-20 ${
        page === "Profile" || page === "Activity" || page === "Organization"
          ? "px-2 sm:px-5"
          : page === "CreatePost"
          ? "px-2 py-4 sm:px-2 sm:pb-6"
          : page === "Account" ||
            page === "Login" ||
            page === "Signup" ||
            page === "Forgot" ||
            page === "Friends" ||
            page === "Onboarding" ||
            page === "Notifications" ||
            page === "Admin Dash" ||
            page === "Seller Dash"
          ? "px-2 py-4 sm:px-5 sm:pb-6"
          : "px-6 sm:px-5"
      } text-black flex items-center justify-between w-full`}
    >
      {page === "Activity" ||
      page === "Organization" ||
      page === "CreatePost" ||
      page === "Notifications" ||
      page === "Account" ||
      (page === "Play" && (orgShortForm || activityShortForm)) ||
      (page === "Comms" && forumTitle) ||
      (page === "Friends" && (orgShortForm || activityShortForm)) ? (
        <button
          onClick={(e) => {
            e.preventDefault();

            if (page === "Comms" && forumTitle && !postNumber) {
              navigate("/comms");
            } else if (
              page === "CreatePost" &&
              (values?.city || values?.country)
            ) {
              setValues({ ...values, city: null, country: null });
            } else if (location.key === "default") {
              navigate("/");
            } else {
              navigate(-1, { state: { from: location } });
            }
          }}
          className="transition-all h-[1.9rem] origin-left pr-2 rounded-full outline-none hover:opacity-50 focus:outline-none"
        >
          <svg
            className="h-4 fill-current"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" />
          </svg>
        </button>
      ) : (
        <button
          onClick={(e) => {
            e.preventDefault();

            if (location.key === "default") {
              navigate("/");
            } else {
              navigate("/", { state: { from: location } });
            }
          }}
          className="transition-all outline-none hover:opacity-50 py-1 focus:outline-none"
        >
          <Logo dimensions={"h-5 sm:h-6 xl:h-5"} />
        </button>
      )}

      {forumTitle ? (
        <>
          <div className="sm:text-lg flex items-center justify-start truncate 2xl:text-2xl text-white  overflow-hidden motion-safe:animate-fadeIn relative space-x-2 font-semibold transition-all leading-tight flex-1 sm:ml-2">
            <img
              src={forumLogoUrl}
              alt={forumTitle}
              className="border-white h-7 sm:h-8 group-hover:opacity-50 transition-all bg-white short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-md sm:rounded-lg object-cover aspect-square"
            />
            <p className="font-semibold hidden sm:block truncate whitespace-nowrap">
              {forumTitle}
            </p>
          </div>
        </>
      ) : activityName ? (
        <>
          <div className="sm:text-lg flex items-center justify-start truncate 2xl:text-2xl text-white  overflow-hidden motion-safe:animate-fadeIn relative space-x-2 font-semibold transition-all leading-tight flex-1 sm:ml-2">
            <img
              src={activityLogoUrl}
              alt={activityName}
              className="border-white h-7 sm:h-8 group-hover:opacity-50 transition-all bg-white short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-md sm:rounded-lg object-cover aspect-square"
            />
            {page !== "CreatePost" && page !== "Friends" && (
              <p className="font-semibold hidden sm:block truncate whitespace-nowrap">
                {activityName}
              </p>
            )}
          </div>
        </>
      ) : (
        <></>
      )}

      <div className=" flex items-center justify-end sm:space-x-2 space-x-2">
        {/* {(page === "Play" || page === "Search") && (
            <button
              onClick={(e) => {
                navigate("/search");
              }}
              className={`group leading-tight text-center text-sm sm:hidden font-semibold ${
                page === "Search" ? "hidden" : ""
              } hover:bg-opacity-70 transition-all bg-lighter-blue relative aspect-square h-[1.9rem] sm:h-7 flex text-white  items-center justify-center shrink-0 rounded-full py-0.5 sm:text-base `}
            >
              <svg
                className=" aspect-square h-4  "
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27 27L20.9583 20.9583M24.2222 13.1111C24.2222 19.2476 19.2476 24.2222 13.1111 24.2222C6.97461 24.2222 2 19.2476 2 13.1111C2 6.97461 6.97461 2 13.1111 2C19.2476 2 24.2222 6.97461 24.2222 13.1111Z"
                  stroke="#1E1E1E"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )} */}

        {/* {customer && page !== "Friends" && page !== "CreatePost" && (
            <button
              onClick={(e) => {
                navigate(
                  "/post" + (activityShortForm ? "/" + activityShortForm : "")
                );
              }}
              className="group relative h-[1.9rem] sm:h-auto hidden sm:flex text-black  transition-all items-center justify-center shrink-0 py-0.5 text-sm sm:text-base "
            >
              <div className="bg-yellow-400 space-x-1 aspect-square h-5 sm:h-6 px-2  opacity-100 group-hover:opacity-60 flex items-center justify-center rounded-full border-2 border-black">
                <p className="text-sm font-medium">Post</p>
                <svg
                  className=" aspect-square h-3 fill-current text-gray-800"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5 12.5714H13.0714V22H9.92857V12.5714H0.5V9.42857H9.92857V0H13.0714V9.42857H22.5V12.5714Z" />
                </svg>
              </div>
            </button>
          )} */}

        {/* {page !== "Friends" && (
            <button
              onClick={(e) => {
                e.preventDefault();

                toggleBuySubscriptionForm(0);
              }}
              className="text-lg 2xl:text-2xl text-black hover:text-gray-700 overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-full"
            >
              <p className="font-semibold whitespace-nowrap">Shop</p>
            </button>
          )} */}

        {page !== "Friends" && page !== "CreatePost" && (
          <a
            href="/"
            rel="noreferrer"
            className={`${
              page === "Profile" ? "bg-white" : "hover:bg-lighter-blue"
            } px-3 text-lg 2xl:text-2xl text-black overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg`}
          >
            <p className="font-semibold whitespace-nowrap">Home</p>
          </a>
        )}
        {page !== "Friends" && page !== "CreatePost" && (
          <a
            href="/play"
            rel="noreferrer"
            className={`${
              page === "Play" ? "bg-white" : "hover:bg-lighter-blue"
            } px-3 text-lg 2xl:text-2xl text-black overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg`}
          >
            <p className="font-semibold whitespace-nowrap">Play</p>
          </a>
        )}

        {customer &&
          targetCustomer &&
          !targetCustomer.isPaidPetSubscriber &&
          page !== "Friends" && (
            <button
              onClick={(e) => {
                e.preventDefault();

                toggleBuySubscriptionForm(0);
              }}
              className={`hover:bg-lighter-blue px-3 text-lg 2xl:text-2xl text-black overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg`}
            >
              <p className="font-semibold whitespace-nowrap">Unlock Pro</p>
            </button>
          )}

        {page !== "Friends" && page !== "CreatePost" && (
          <a
            href="/shop"
            rel="noreferrer"
            className={`${
              page === "Shop" ? "bg-white" : "hover:bg-lighter-blue"
            } px-3 text-lg 2xl:text-2xl text-black overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg`}
          >
            <p className="font-semibold whitespace-nowrap">Shop</p>
          </a>
        )}

        {page !== "Friends" && page !== "CreatePost" && (
          <a
            href="/registerSeller"
            rel="noreferrer"
            className={`${
              page === "Seller Dash" ? "bg-white" : "hover:bg-lighter-blue"
            } px-3 text-lg 2xl:text-2xl text-black overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg`}
          >
            <p className="font-semibold whitespace-nowrap">Advertise</p>
          </a>
        )}

        {numUnclickedNotifications > 0 &&
        page !== "TKL" &&
        page !== "Battle" &&
        page !== "Notifications" ? (
          <button
            onClick={(e) => {
              navigate("/notifications");
            }}
            className="group text-center text-sm font-semibold bg-red-600 hover:bg-opacity-70 transition-all relative aspect-square h-[1.9rem] sm:h-7 flex text-white  items-center justify-center shrink-0 rounded-full py-0.5 sm:text-base "
          >
            <p className="text-center leading-none sm:h-5 m-auto text-black">
              {numUnclickedNotifications}
            </p>

            <div className="sm:flex hidden font-semibold flex-col absolute items-center justify-center top-full left-1/2 transform -translate-x-1/2 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-300">
              <svg
                className="h-1.5 rotate-180 fill-current text-gray-800"
                viewBox="0 0 20 10"
              >
                <path d="M0 0L20 0L10 10Z" />
              </svg>
              <span className="bg-gray-800 px-2 py-1  rounded-md shadow">
                Notifications
              </span>
            </div>
          </button>
        ) : (
          <></>
        )}

        {seller ? (
          <></>
        ) : (
          <PlayerCoinCounter
            targetCustomer={targetCustomer || targetGuestCustomer}
            toggleBuyCoinsForm={toggleBuyCoinsForm}
          />
        )}
        {/* <button
          className={`${
            targetCustomer
              ? targetCustomer.username === "lucky"
                ? "bg-blue-300"
                : // : targetCustomer.petSubscriptionType === 0
                  // ? "bg-yellow-300"
                  // : targetCustomer.petSubscriptionType === 1
                  // ? "bg-red-400"
                  "bg-black"
              : "bg-black"
          } group hover:bg-opacity-30 flex-shrink-0 transition-all overflow-hidden p-[.2rem] 2xl:p-1.5 rounded-full aspect-square w-8 hidden sm:flex items-center justify-center 2xl:w-14`}
          onClick={(e) => {
            e.preventDefault();
            setIsFirstTime(false);
            setShouldShowMiniNavBar(true);
          }}
        >
          <img
            src={
              targetCustomer
                ? targetCustomer.profilePicUrl
                : seller
                ? seller.profilePicUrl
                : defaultPicUrl
            }
            alt={
              targetCustomer
                ? targetCustomer.username
                : seller
                ? seller.username
                : "Knight"
            }
            className="border-white group-hover:bg-white transition-all bg-black short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-full object-cover aspect-square"
          />
        </button> */}
      </div>
    </div>
  );
}
